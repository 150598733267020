import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import "../App.css";
import instruction1 from "../assets/images/t1.png";
import instruction2 from "../assets/images/t2.png";
import gameImage from "../bg-game.png";
import { useGlobalContext } from "../context";
import useCustomLogin from "../hook/useLogin";
import { getPlatform } from "../utils";
import { Alert } from "react-bootstrap";

function Login() {
  const { login, guestLogin, ready } = useCustomLogin();
  const { errorMessage, setErrorMessage, setDeferredPrompt, deferredPrompt } =
    useGlobalContext();
  const [showInstall, setShowInstall] = useState(true);
  const [platform, setPlatform] = useState("");
  const [showError, setShowError] = useState(false);
  const telegramBot = process.env.REACT_APP_TELEGRAM_BOT_NAME;
  const telegrameApp = process.env.REACT_APP_TELEGRAM_APP_NAME;
  const [isLoggedOut, setIsLoggedOut] = useState(
    localStorage.getItem("isLoggedOut") || false
  );

  useEffect(() => {
    if (isRunOnTelegram()) {
      injectCSS(`
        div#privy-modal-content {
          border-radius: var(--privy-border-radius-lg) !important;  
        }
          
        @media (min-width: 441px) {
          .jXYaRF {
            position: relative !important;
            transform: none !important;
            transition: none !important;
            opacity: 1 !important;
          }
        }  
          
        @media (max-width: 440px) {
          .jXYaRF {
            position: relative !important;
            transform: none !important;
            transition: none !important;
            opacity: 1 !important;
          }
        }  

        div.jXYaRF: {
          position: relative !important;
          transform: none !important;
          transition: none !important;
          opacity: 1 !important;
        }
      `);
    }

    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    const platform = getPlatform();
    setPlatform(platform);

    const checkStandalone = () => {
      const isDesktopOrTelegram =
        isRunOnTelegram() ||
        window.matchMedia("(display-mode: standalone)").matches;

      console.log("isDesktopOrTelegram", isDesktopOrTelegram);

      const isMobile = !isDesktopOrTelegram;
      setShowInstall(isMobile);
    };

    checkStandalone();

    if (!isLoggedOut) {
      guestLogin();
    }

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, [isLoggedOut]);

  useEffect(() => {
    localStorage.setItem("isLoggedOut", isLoggedOut);
  }, [isLoggedOut]);

  const handleLogin = () => {
    if (isRunOnTelegram()) {
      if (process.env.REACT_APP_ENVIRONMENT === "staging") {
        login({ loginMethods: ["email", "sms", "wallet"] });
      } else {
        login({ loginMethods: ["email", "sms"] });
      }
    } else {
      login({
        loginMethods: ["email", "sms", "google", "twitter", "wallet"],
      });
    }
  };

  const handleGuestLogin = () => {
    guestLogin();
  };

  const handleAddToHomeScreen = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        setDeferredPrompt(null);
      });
    } else {
      setErrorMessage("App already installed.");
    }
  };

  const isRunOnTelegram = () => {
    return window.Telegram.WebView.initParams.tgWebAppData !== undefined;
  };

  useEffect(() => {
    if (errorMessage) {
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  function injectCSS(css) {
    const style = document.createElement("style");
    style.type = "text/css";
    style.appendChild(document.createTextNode(css));
    document.head.appendChild(style);
  }

  return ready ? (
    <div className="App">
      <header className="App-header">
        <div className="Game-container" style={{ position: "relative" }}>
          {showError && (
            <div className="login-error-message">
              <span>⛔ Error! ⛔</span>
              <br />
              <span>{errorMessage}</span>
            </div>
          )}
          <img src={gameImage} alt="game" className="Game-image" />
          {isLoggedOut ? (
            <>
              {isMobile && platform === "Android" ? (
                <>
                  {showInstall ? (
                    <div className="instruction-wrapper">
                      <div className="pop-android">
                        <div className="toptext">
                          <span>Add to home page</span>
                        </div>
                        <div className="pop2-android">
                          <div className="instruction-text-android">
                            To start playing, you need to add this website to
                            home screen
                          </div>
                        </div>
                        <div className="install-btn-wrapper">
                          <button
                            onClick={handleAddToHomeScreen}
                            className="install-button"
                          >
                            <span>Install</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div className="login-wrapper">
                        {isRunOnTelegram() && (
                          <Alert
                            variant="light"
                            className="telegram-platform-info"
                          >
                            Play on fishingfrenzy.co for more login options.
                          </Alert>
                        )}
                        <button onClick={handleLogin} className="Play-button">
                          <span className="press-start-2p-regular">Log In</span>
                        </button>
                        <button
                          onClick={handleGuestLogin}
                          className="Play-as-guest-button"
                        >
                          <span className="press-start-2p-regular">
                            Play as Guest
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                </>
              ) : isMobile && platform !== "Android" ? (
                <>
                  {showInstall ? (
                    <div className="instruction-wrapper">
                      <div className="pop">
                        <div className="toptext">
                          <span>Add to home page</span>
                        </div>
                        <div className="pop2">
                          <div className="instruction-text">
                            To start playing, you need to add this website to
                            home screen
                          </div>
                          <div className="instruction-step-wrapper">
                            <div className="step-wrapper">
                              <div className="d-flex gap-2 justify-content-round">
                                <div className="d-flex flex-column justify-content-between align-items-center flex-fill">
                                  <span className="sub-title">
                                    Step <p className="d-inline step">1</p>
                                  </span>
                                  <img
                                    src={instruction1}
                                    className="step-image"
                                    alt=""
                                  />
                                </div>
                                <div className="d-flex gap-3 flex-column justify-content-round align-items-center flex-fill">
                                  <span className="sub-title">
                                    Step <p className="d-inline step">2</p>
                                  </span>
                                  <img
                                    src={instruction2}
                                    className="step-image"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div className="login-wrapper">
                        {isRunOnTelegram() && (
                          <Alert
                            variant="light"
                            className="telegram-platform-info"
                          >
                            Play on fishingfrenzy.co for more login options.
                          </Alert>
                        )}
                        <button onClick={handleLogin} className="Play-button">
                          <span className="press-start-2p-regular">Log In</span>
                        </button>
                        <button
                          onClick={handleGuestLogin}
                          className="Play-as-guest-button"
                        >
                          <span className="press-start-2p-regular">
                            Play as Guest
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="login-wrapper">
                    {isRunOnTelegram() && (
                      <Alert variant="light" className="telegram-platform-info">
                        Play on fishingfrenzy.co for more login options.
                      </Alert>
                    )}
                    <button onClick={handleLogin} className="Play-button">
                      <span className="press-start-2p-regular">Log In</span>
                    </button>
                    <button
                      onClick={handleGuestLogin}
                      className="Play-as-guest-button"
                    >
                      <span className="press-start-2p-regular">
                        Play as Guest
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </header>
    </div>
  ) : (
    <></>
  );
}

export default Login;
