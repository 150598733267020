import {
  addRpcUrlOverrideToChain,
  useFundWallet,
  useLinkAccount,
  usePrivy,
} from "@privy-io/react-auth";
import React, { useEffect, useRef, useState } from "react";
import { base, baseSepolia } from "viem/chains";
import useCustomLogout from "./hook/useLogout";
import usePurchase from "./hook/usePurchase";
import useTicket from "./hook/useTicket";
import useTwitter from "./hook/useTwitter";
import useWithdraw from "./hook/useWithdraw";
import useChest from "./hook/useChest";
import useRod from "./hook/useRod";

let baseCustomRPC;

const isProductionEVN = process.env.REACT_APP_ENVIRONMENT === "prod";

if (isProductionEVN) {
  baseCustomRPC = addRpcUrlOverrideToChain(
    base,
    process.env.REACT_APP_RPC_BASE_SEPOLIA
  );
} else {
  baseCustomRPC = addRpcUrlOverrideToChain(
    baseSepolia,
    process.env.REACT_APP_RPC_BASE_SEPOLIA
  );
}

const telegramBot = process.env.REACT_APP_TELEGRAM_BOT_NAME;
const telegramApp = process.env.REACT_APP_TELEGRAM_APP_NAME;

const GameComponent = () => {
  const [tokenId, setTokenId] = useState();
  const [tokenIds, setTokenIds] = useState([]); // State for list of tokenIds
  const [devMode, setDevMode] = useState(false);
  const { user, unlinkTwitter } = usePrivy();
  const { postTweet } = useTwitter();
  const { customLogout } = useCustomLogout();
  const { purchaseGold, purchaseStarterPack } = usePurchase();
  const { purchaseTournamentTicket } = useTicket();
  const { handleWithdraw } = useWithdraw();
  const { openNFTChest } = useChest();
  const { depositRod, withdrawRod } = useRod();
  const { exportWallet } = usePrivy();
  const { updateTwitterInfo } = useTwitter();
  const { linkTwitter } = useLinkAccount({
    onSuccess: async (linkedAccount) => {
      await updateTwitterInfo(linkedAccount.twitter);
    },
  });

  const { fundWallet } = useFundWallet({
    onUserExited() {
      refreshUserData(null, true, true);
    },
  });

  const handleInputChange = (event) => {
    setTokenId(event.target.value);
  };

  const handleTokenIdsChange = (event) => {
    const input = event.target.value;
    // Split the input by commas, trim whitespace, and parse as integers
    const ids = input
      .split(",")
      .map((id) => id.trim())
      .filter(Boolean);
    setTokenIds(ids);
  };

  const toggleDevMode = () => {
    setDevMode((prevDevMode) => !prevDevMode);
  };

  const twitterSubject = user?.twitter?.subject || null;

  const urlWebsocketEndpoint = process.env.REACT_APP_FISH_WEBSOCKET_ENDPOINT;
  localStorage.setItem("fish_websocket_endpoint", urlWebsocketEndpoint);

  const [containerStyle, setContainerStyle] = useState({
    height: "100dvh",
    width: "100vw",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  });

  const iframeRef = useRef(null);
  const [gameState, setGameState] = useState(() => {
    const savedState = localStorage.getItem("cocosGameState");
    return savedState ? JSON.parse(savedState) : null;
  });

  useEffect(() => {
    return () => {
      localStorage.removeItem("reauth_done");
    };
  }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.source === iframeRef.current?.contentWindow) {
        localStorage.setItem("cocosGameState", JSON.stringify(event.data));
        setGameState(event.data);
        if (event.data.type === "buttonLogOutClicked") {
          cocosLogoutBtnClick(event.data.data);
        } else if (event.data.type === "buttonBuyCoinClicked") {
          cocosExchangeGoldBtnClick(event.data.data);
        } else if (event.data.type === "buttonPurchaseStarterPackClicked") {
          cocosPurchaseStarterPackBtnClick(event.data.data);
        } else if (event.data.type === "buttonBuyEventTicketClicked") {
          cocosBuyEventTicketBtnClick(event.data.amountETH);
        } else if (event.data.type === "buttonDepositClicked") {
          cocosDepositBtnClick(event.data.message);
        } else if (event.data.type === "buttonExportWalletClicked") {
          cocosExportWalletBtnClick(event.data.data);
        } else if (event.data.type === "buttonWithdrawClicked") {
          cocosWithdrawBtnClick(event.data.address, event.data.amountETH);
        } else if (event.data.type === "buttonLinkTwitterClicked") {
          cocosLinkTwitterBtnClick(event.data.data);
        } else if (event.data.type === "buttonUnlinkTwitterClicked") {
          cocosUnlinkTwitterBtnClick(event.data.data);
        } else if (event.data.type === "buttonShareFishClicked") {
          cocosShareFishBtnClick(event.data.data);
        } else if (event.data.type === "buttonShareOnTelegram") {
          cocosShareTelegramBtnClick(event.data.data);
        } else if (event.data.type === "OpenTelegramInvoice") {
          cocosOpenTelegramInvoice(event.data.data);
        } else if (event.data.type === "OpenHttpLink") {
          cocosOpenLink(event.data.data);
        } else if (event.data.type === "buttonOpenNFTChestClicked") {
          cocosOpenNFTChestBtnClick(event.data.tokenId);
        } else if (event.data.type === "buttonDepositNFTRodClicked") {
          cocosDepositRodBtnClick(event.data.tokenIds);
        } else if (event.data.type === "buttonWithdrawNFTRodClicked") {
          cocosWithdrawRodBtnClick(event.data.tokenIds);
        } else {
          const authToken = localStorage.getItem("fishAuth");
          const userId = localStorage.getItem("userId");
          const beAddress = localStorage.getItem("fish_be_endpoint");
          const websocketAddress = localStorage.getItem(
            "fish_websocket_endpoint"
          );
          const isTelegram =
            window.Telegram.WebView.initParams.tgWebAppData != undefined;

          iframeRef.current.contentWindow.postMessage(
            {
              authToken: authToken,
              userId: userId,
              beAddress: beAddress,
              websocketAddress: websocketAddress,
              isTelegram: isTelegram,
              telegramBot: telegramBot,
              telegramApp: telegramApp,
            },
            "*"
          );
        }
      }
    };

    window.addEventListener("message", handleMessage);

    // Restore state after iframe is loaded
    const onIframeLoad = () => {
      if (gameState && iframeRef.current) {
        iframeRef.current.contentWindow.postMessage(
          { type: "RESTORE_STATE", state: gameState },
          "*"
        );
      }
    };

    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener("load", onIframeLoad);
    }

    return () => {
      window.removeEventListener("message", handleMessage);
      if (iframe) {
        iframe.removeEventListener("load", onIframeLoad);
      }
    };
  }, [gameState]);

  const refreshDataInventory = async (data) => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage(
        {
          type: "refreshDataInventory",
          hasWaiting: data.hasWaiting,
          notifyMessage: data.notifyMessage,
        },
        "*"
      );
    }
  };

  const activeWaiting = async (isWaiting) => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage(
        { type: "activeWaiting", isWaiting: isWaiting },
        "*"
      );
    }
  };

  const refreshUserData = async (message, isSuccess, hasWaiting) => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage(
        {
          type: "refreshUserData",
          message,
          isSuccess,
          hasWaiting,
        },
        "*"
      );
    }
  };

  const getBuyTicketResult = async (message, isSuccess, hasWaiting) => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage(
        { type: "buyTicketResult", message, isSuccess, hasWaiting },
        "*"
      );
    }
  };

  const purchasePackResult = async (message, isSuccess, hasWaiting) => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage(
        { type: "purchasePackResult", message, isSuccess, hasWaiting },
        "*"
      );
    }
  };

  const getOpenNFTChestResult = async (
    message,
    isSuccess,
    hasWaiting,
    data
  ) => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage(
        { type: "openNFTChestResult", message, isSuccess, hasWaiting, data },
        "*"
      );
    }
  };

  const getDepositNFTRodResult = async (
    message,
    isSuccess,
    hasWaiting,
    data
  ) => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage(
        { type: "depositNFTRodResult", message, isSuccess, hasWaiting, data },
        "*"
      );
    }
  };
  const getWithdrawNFTRodResult = async (
    message,
    isSuccess,
    hasWaiting,
    data
  ) => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage(
        { type: "withdrawNFTRodResult", message, isSuccess, hasWaiting, data },
        "*"
      );
    }
  };

  const notifyReact = async (data) => {
    if (gameState && iframeRef.current) {
      iframeRef.current.contentWindow.postMessage(
        {
          type: "notifyReact",
          message: data.message,
          isSuccess: data.isSuccess,
        },
        "*"
      );
    }
  };

  const cocosLogoutBtnClick = async (data) => {
    customLogout();
  };

  const cocosExchangeGoldBtnClick = async (data) => {
    activeWaiting(true);
    const { success, message } = await purchaseGold(data);
    activeWaiting(false);
    refreshUserData(message, success, true);
  };

  const cocosPurchaseStarterPackBtnClick = async (data) => {
    activeWaiting(true);
    const { success, message } = await purchaseStarterPack(data);
    activeWaiting(false);
    purchasePackResult(message, success, true);
  };

  const cocosBuyEventTicketBtnClick = async (amountETH) => {
    activeWaiting(true);
    const { success, message } = await purchaseTournamentTicket(amountETH, 1);
    activeWaiting(false);
    getBuyTicketResult(message, success, true);
  };

  const cocosDepositBtnClick = async (amount) => {
    await fundWallet(user.wallet.address, {
      chain: baseCustomRPC,
      amount: amount.toString(),
    });
  };

  const cocosExportWalletBtnClick = (data) => {
    exportWallet();
  };

  const cocosLinkTwitterBtnClick = async (data) => {
    if (!user.twitter?.subject) {
      linkTwitter();
    } else {
      console.warn("Twitter account has already linked!");
    }
  };

  const cocosUnlinkTwitterBtnClick = async (data) => {
    try {
      await unlinkTwitter(twitterSubject);
      await updateTwitterInfo(null);
      refreshDataInventory({
        notifyMessage: "Successfully unlink X",
        hasWaiting: true,
      });
    } catch (error) {
      console.error("Unlink Twitter failed:", error);
    }
  };

  const cocosWithdrawBtnClick = async (address, amount) => {
    const tx = await handleWithdraw(address.trim(), amount.trim());
    if (tx && tx.status === 1) {
      refreshUserData(null, true, true);
    }
  };

  const cocosShareFishBtnClick = async (data) => {
    await postTweet(data);
  };

  const cocosShareTelegramBtnClick = async (refCode) => {
    const message = "FISHING FRENZY" + "\n";
    const link =
      "https://t.me/share/url?url=https://t.me/" +
      telegramBot +
      "/" +
      telegramApp +
      "?startapp=" +
      refCode; // + "&text=" + encodeURIComponent(message);

    // window.Telegram.WebApp.openLink(link);
    window.Telegram.WebApp.openTelegramLink(link);
  };

  const cocosOpenLink = async (link) => {
    window.Telegram.WebApp.openLink(link);
  };

  const cocosOpenTelegramInvoice = async (invoiceLink) => {
    window.Telegram.WebApp.openLink(invoiceLink);
  };

  const cocosOpenNFTChestBtnClick = async (tokenId) => {
    const { success, message, data } = await openNFTChest(tokenId);
    getOpenNFTChestResult(message, success, true, data);
  };

  const cocosDepositRodBtnClick = async (tokenIds) => {
    activeWaiting(true);
    const { success, message, data } = await depositRod(tokenIds);
    activeWaiting(false);
    getDepositNFTRodResult(message, success, true, data);
  };

  const cocosWithdrawRodBtnClick = async (tokenIds) => {
    activeWaiting(true);
    const { success, message, data } = await withdrawRod(tokenIds);
    activeWaiting(false);
    getWithdrawNFTRodResult(message, success, true, data);
  };

  return (
    <div style={containerStyle}>
      {!isProductionEVN && (
        <>
          <button onClick={toggleDevMode} className="First-button">
            {devMode ? "Exit Dev Mode" : "Enter Dev Mode"}
          </button>

          {devMode && (
            <>
              <input
                className="Second-button"
                type="number"
                value={tokenId}
                onChange={handleInputChange}
                placeholder="TestInput: chestId"
              />
              <button
                onClick={() => openNFTChest(tokenId)}
                className="Third-button"
              >
                Test button: Open chest
              </button>
              <input
                className="Fourth-button"
                type="text"
                placeholder="Test Enter token IDs, comma-separated"
                onChange={handleTokenIdsChange}
              />
              <button
                className="Fifth-button"
                onClick={() => depositRod(tokenIds)}
              >
                Test: DepositRods
              </button>
              <button
                className="Sixth-button"
                onClick={() => withdrawRod(tokenIds)}
              >
                Test: WithdrawRods
              </button>
            </>
          )}
        </>
      )}
      <iframe
        ref={iframeRef}
        src="/cocos/index.html"
        title="Cocos Game"
        style={{ width: "100%", height: "100%", border: "none" }}
      />
    </div>
  );
};

export default GameComponent;
